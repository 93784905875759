<template>
  <div>
    <h2 :class="headerClass">
      {{ headerText }}
    </h2>
    <div :class="containerClass">
      <div :class="columnClass">
        <slot name="firstColumn" />
      </div>
      <div :class="columnClass">
        <slot name="secondColumn" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoColumnFeatureBlock',

  props: {
    headerClass: {
      type: String,
      default: 'tw-text-center tw-mb-4 tw-text-2xl tw-font-medium',
    },
    headerText: {
      type: String,
      default: null,
    },
  },

  computed: {
    columnClass() {
      return 'md:tw-w-2/5 lg:tw-w-1/3 md:tw-mx-2 lg:tw-mx-3 tw-mb-6 md:tw-mb-0';
    },

    containerClass() {
      return 'md:tw-flex md:tw-justify-center md:tw-flex-wrap tw-mb-14';
    },
  },
};
</script>
