<template>
  <Card
    :has-body-padding="true"
    :is-full-height="true"
  >
    <template #body>
      <p
        class="tw-mb-2.5 tw-font-normal"
      >
        "{{ copy }}"
      </p>
      <p
        class="tw-text-right tw-font-normal text-light-color"
      >
        - {{ credit }}
      </p>
    </template>
  </Card>
</template>

<script>
export const reviewers = {
  cody: 'cody',
  dale: 'dale',
  ed: 'ed',
  hope: 'hope',
  jon: 'jon',
  mark: 'mark',
  nikolai: 'nikolai',
  pauline: 'pauline',
};

const reviews = {
  [reviewers.cody]: {
    copy: 'Weather forecasting for the mountains takes extra focus, analysis, and precision, which is exactly what OpenSnow provides.',
    credit: 'Cody Townsend, Pro Skier',
  },
  [reviewers.ed]: {
    copy: 'If you are a person who likes to hike, bike or just visit the high country and want to have the most accurate weather forecast at various elevations, OpenSnow is absolutely the best app.',
    credit: 'Ed, iOS Review',
  },
  [reviewers.jon]: {
    copy: 'Any weather app can give a mediocre forecast for a mountain town, but only OpenSnow provides a good idea of actual mountain ski conditions. As a powder hunter that hits the slopes >30 days per season, OpenSnow is my go-to source for planning where I ski.',
    credit: 'Jon, iOS Review',
  },
  [reviewers.mark]: {
    copy: "Excellent app for weather forecasts with lots of useful information all in one place. I've used it often to plan hikes and climbs and found the accuracy to be spot on. I wouldn't think of going into the mountains without checking OpenSnow first.",
    credit: 'Mark, Android Review',
  },
  [reviewers.pauline]: {
    copy: "The OpenSnow app is an indispensable tool. It is well worth the All-Access upgrade considering you get access to a 10-day HIGHLY accurate forecast. It's a small price to pay for finding fresh powder.",
    credit: 'Pauline, Android Review',
  },
  [reviewers.nikolai]: {
    copy: 'The number one factor I depend on for success in the mountains is accurate weather forecasts and snow reports. OpenSnow has become an indispensable part of my toolkit, simply by being the best.',
    credit: 'Nikolai Schirmer, Pro Athlete',
  },
  [reviewers.dale]: {
    copy: 'OpenSnow is the app for researching weather and localized snow conditions in ski and snow sports areas. The map feature with radar is my go-to for visualizing real-time precipitation, especially for snowfall in the mountains.',
    credit: 'Dale, iOS Review',
  },
  [reviewers.hope]: {
    copy: 'I love this app! I use OpenSnow daily to plan my snowboarding trips throughout the season. It\'s more accurate than generalized weather apps and when combined with the reports from my local mountains, I always know exactly what to expect for the day. It\'s well worth the All-Access subscription.',
    credit: 'Hope, iOS Review',
  },
};

export default {
  name: 'ReviewCard',

  props: {
    reviewer: {
      type: String,
      required: true,
      validator(reviewer) {
        return reviewers[reviewer] !== undefined;
      },
    },
  },

  computed: {
    copy() {
      return reviews[this.reviewer].copy;
    },

    credit() {
      return reviews[this.reviewer].credit;
    },
  },
};
</script>
