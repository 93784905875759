<template>
  <FeatureCard
    v-bind="featureCardProps"
  />
</template>

<script>
import { getOpenSnowFeatures } from '@@/components/FAQs/FaqUtils';
import PricingPlanMixin from '@@/components/FAQs/PricingPlanMixin';

export default {
  name: 'OpenSnowGroupFeatureCard',

  mixins: [PricingPlanMixin],

  computed: {
    featureCardProps() {
      return {
        title: this.groupPlanName,
        subTitle: `${this.groupPlanMaxMembers} People, $${this.groupPlanPrice} ${this.groupPlanCurrency}/${this.groupPlanPeriod}`,
        viewExamples: false,
        features: getOpenSnowFeatures(),
      };
    },
  },
};
</script>/
