<template>
  <FeatureCard
    v-bind="featureCardProps"
  />
</template>

<script>
import { getOpenSnowFeatures } from '@@/components/FAQs/FaqUtils';
import PricingPlanMixin from '@@/components/FAQs/PricingPlanMixin';

export default {
  name: 'OpenSnowSingleFeatureCard',

  mixins: [PricingPlanMixin],

  computed: {
    featureCardProps() {
      return {
        title: this.singlePlanName,
        subTitle: `${this.singlePlanMaxMembers} Person, $${this.singlePlanPrice} ${this.singlePlanCurrency}/${this.singlePlanPeriod}`,
        viewExamples: false,
        features: getOpenSnowFeatures(),
      };
    },
  },
};
</script>
