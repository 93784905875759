<template>
  <div>
    <h2 :class="headerClass">
      {{ headerText }}
    </h2>
    <Card
      :has-body-padding="hasBodyPadding"
      :no-body-styles="noBodyStyles"
    >
      <template #body>
        <ul>
          <li
            v-for="faq in faqs"
            :key="faq.question"
          >
            <h3
              class="tw-text-xl tw-mb-2 tw-font-medium"
            >
              {{ faq.question }}
            </h3>
            <p
              class="tw-mb-6"
            >
              {{ faq.answer }}
            </p>
          </li>
        </ul>
      </template>
    </Card>
  </div>
</template>

<script>
import PricingPlanMixin from '@@/components/FAQs/PricingPlanMixin';

export default {
  name: 'FaqList',

  mixins: [PricingPlanMixin],

  props: {
    hasBodyPadding: {
      type: Boolean,
      default: true,
    },
    headerClass: {
      type: String,
      default: 'tw-text-center tw-mb-4 tw-text-2xl tw-font-medium',
    },
    headerText: {
      type: String,
      default: 'Frequently Asked Questions',
    },
    noBodyStyles: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    faqs() {
      return [
        {
          question: 'How much does All-Access cost?',
          answer: `The All-Access Single subscription is available for $${this.singlePlanPrice} ${this.singlePlanCurrency} per year and provides unlimited access for ${this.singlePlanMaxMembers} person. The All-Access Group subscription is available for $${this.groupPlanPrice} ${this.groupPlanCurrency} per year and provides unlimited access for ${this.groupPlanMaxMembers} people.`,
        },
        {
          question: 'Is All-Access good for 365 days?',
          answer: 'Yes, All-Access is valid for 365 days. That means that when you upgrade today, your subscription will be good for the next 365 days. That way you can track every storm during the winter, find corn snow in the spring, avoid lightning and wildfire smoke during the summer, and escape to the desert in the fall.',
        },
        {
          question: 'How does the Group subscription work?',
          answer: `${this.groupPlanMaxMembers} people. ${this.groupPlanMaxMembers} logins. $${this.groupPlanPrice} ${this.groupPlanCurrency} per year. The Group subscription allows you to share your All-Access benefits with ${this.groupPlanMaxMembers - 1} friends or family members, with each member having their own login, favorite locations list, snow alerts, etc. If you already have a Single subscription, go to Settings > Subscription > All-Access Group to upgrade and we will automatically prorate the difference for the time remaining in your subscription.`,
        },
        {
          question: 'Will my All-Access subscription automatically renew?',
          answer: 'Yes, all subscriptions are set to automatically renew unless canceled before the end of the 365-day subscription term. You can easily cancel your subscription via the app or website with one click.',
        },
        {
          question: 'How does the free trial work?',
          answer: 'New subscribers who opt-in to the free trial receive the full OpenSnow experience, with no credit card required. If you choose not to purchase OpenSnow after the free trial is over, you will be automatically downgraded to a free account and not charged. You will still be able to compare snow reports and view weather forecasts.',
        },
        {
          question: 'How do you make your forecasts?',
          answer: 'We update the automated forecasts every hour with a proprietary blend of global and high-resolution model data. Blending multiple global and high-resolution models increases accuracy and confidence in the forecast, among many other benefits. The data can be viewed as hourly forecasts for the next 10 days and daily forecasts for the next 10 days.',
        },
        {
          question: 'What is OpenSnow?',
          answer: 'OpenSnow is a weather forecasting and conditions information service that provides everything that you need for your next outdoor adventure. View 10-day weather forecasts and snow reports for your favorite locations, read expert analysis from local forecasters, track storms with high-resolution 3D weather maps, receive custom snow alerts, and get the latest weather forecast for any location on Earth.',
        },
      ];
    },
  },
};
</script>
