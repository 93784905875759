<template>
  <TwoColumnFeatureBlock
    :header-class="headerClass"
    header-text="OpenSnow Features"
  >
    <template #firstColumn>
      <OpenSnowSingleFeatureCard />
    </template>
    <template #secondColumn>
      <OpenSnowGroupFeatureCard />
    </template>
  </TwoColumnFeatureBlock>
</template>

<script>
export default {
  name: 'OpenSnowFeatures',

  props: {
    headerClass: {
      type: String,
      default: 'tw-text-center tw-mb-4 tw-text-2xl tw-font-medium',
    },
  },
};
</script>
