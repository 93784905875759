<template>
  <div
    v-if="isFree"
    class="tw-flex tw-items-stretch"
  >
    <Button
      v-if="!isTrialing"
      v-bind="freeTrialButtonProps"
      additional-class-names="tw-mr-3 tw-w-full"
    >
      <span class="tw-block tw-text-lg tw-font-normal">
        Start Free Trial
      </span>
      <small class="tw-block tw--mt-1 tw-font-normal tw-text-xs">
        No credit card required
      </small>
    </Button>
    <Button
      v-bind="buyNowButtonProps"
      additional-class-names="tw-w-full tw-inline-block tw-py-3"
    >
      <span class="tw-flex tw-h-full tw-items-center tw-justify-center tw-text-lg">
        Buy Now
      </span>
    </Button>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { addQueryParamsToPath } from '@@/utils/CommonUtils';
import { useUserStore } from '@@/stores/User';

export default {
  name: 'ActionButtons',

  props: {
    buyNowButtonType: {
      type: String,
      default: ButtonTypes.primary,
      validator(value) {
        return Object.values(ButtonTypes).includes(value);
      },
    },
  },

  computed: {
    ...mapState(useUserStore, ['isAllAccess', 'isGuest', 'isTrialing', 'isFree']),

    buyNowButtonProps() {
      const to = addQueryParamsToPath('/buy', { source: 'all_access_faq' });

      return {
        large: true,
        to,
        type: (this.isTrialing) ? ButtonTypes.allAccess : this.buyNowButtonType,
      };
    },

    freeTrialButtonProps() {
      const to = this.isGuest
        ? '/user/register?source=all_access_faq'
        : '/user/settings/start-trial?source=all_access_faq';

      return {
        additionalClassNames: 'tw-whitespace-nowrap',
        large: true,
        to,
        type: ButtonTypes.allAccess,
      };
    },
  },
};
</script>
