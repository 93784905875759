<template>
  <div>
    <h2 :class="headerClass">
      <span class="tw-block">Read Real Reviews</span>
      <font-awesome-icon icon="star" />
      <font-awesome-icon icon="star" />
      <font-awesome-icon icon="star" />
      <font-awesome-icon icon="star" />
      <font-awesome-icon icon="star" />
    </h2>
    <div :class="reviewsContainerClass">
      <div
        v-for="reviewer in reviewers"
        :key="reviewer"
        :class="reviewItemClass"
      >
        <ReviewCard :reviewer="reviewer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpenSnowReviews',

  props: {
    headerClass: {
      type: String,
      default: 'tw-text-center tw-mb-4 tw-text-2xl tw-font-medium',
    },
    reviewers: {
      type: Array,
      required: true,
    },
  },

  computed: {
    reviewsContainerClass() {
      return [
        'md:tw-grid',
        'md:tw-grid-cols-2 lg:tw-grid-cols-3 md:tw-gap-2.5',
        'tw-mb-14',
      ];
    },

    reviewItemClass() {
      return 'tw-mb-4 md:tw-mb-0';
    },
  },
};
</script>
