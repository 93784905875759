import { mapState } from 'pinia';
import { useMetaStore } from '@@/stores/Meta';

const mapPeriod = (period = 'yearly') => {
  if (period === 'yearly') {
    return 'year';
  }

  if (period === 'monthly') {
    return 'month';
  }

  if (period === 'weekly') {
    return 'week';
  }

  if (period === 'daily') {
    return 'day';
  }

  return period;
};

export default {
  computed: {
    ...mapState(useMetaStore, { pricingPlans: 'getPricingPlans' }),

    groupPlanCurrency() {
      return this.pricingPlans?.single?.price_currency || 'USD';
    },

    groupPlanMaxMembers() {
      return this.pricingPlans?.group?.max_members || 4;
    },

    groupPlanName() {
      return this.pricingPlans?.group?.name || 'All-Access Group';
    },

    groupPlanPeriod() {
      return mapPeriod(this.pricingPlans?.group?.period);
    },

    groupPlanPrice() {
      return this.pricingPlans?.group?.price || 39.99;
    },

    singlePlanCurrency() {
      return this.pricingPlans?.single?.price_currency || 'USD';
    },

    singlePlanMaxMembers() {
      return this.pricingPlans?.single?.max_members || 1;
    },

    singlePlanName() {
      return this.pricingPlans?.single?.name || 'All-Access Single';
    },

    singlePlanPeriod() {
      return mapPeriod(this.pricingPlans?.single?.period);
    },

    singlePlanPrice() {
      return this.pricingPlans?.single?.price || 29.99;
    },
  },
};
